<template>
  <section class="cont marketing-members-cont">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>营销云</el-breadcrumb-item>
        <el-breadcrumb-item>营销模板</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <!-- 查询框 -->
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>模板名称：</label>
            <el-input v-model="name" clearable placeholder="请输入名称"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>模板状态：</label>
            <el-select v-model="status" @change="getMarketingTemplate(1)" :placeholder="$t('msg.select')" clearable>
              <el-option v-for="item in stateOpt" :key="item.value" :label="item.value" :value="item.status"></el-option>
            </el-select>
          </el-row>
          <el-row class="search-item">
            <el-button type="primary" @click="getMarketingTemplate(1)">搜索</el-button>
            <el-button @click="getMarketingTemplate(0, 'reset')">重置</el-button>
          </el-row>
          <el-button type="primary" @click="tagManagement">标签管理</el-button>
          <el-button type="primary" @click="addTemplate('add')">新增模板</el-button>
        </el-row>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格 -->
        <el-table v-loading="tableLoad" border fit stripe :data="tableData">
          <el-table-column label="序号" type="index" width="60"></el-table-column>
          <el-table-column prop="name" label="模板名称" width="110"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              {{ scope.row.contentType | contentTypeFormat }}
            </template>
          </el-table-column>
          <el-table-column label="模板标签" width="300">
            <template slot-scope="scope">
              <el-tag size="small" v-for="(item, index) in scope.row.tagName.split(',')" :key="index">{{ item }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
          <el-table-column label="使用热度">
            <template slot-scope="scope">
              {{scope.row.useHot ? scope.row.useHot : 0}}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ scope.row.status == 'YES' ? '正常' : '暂停' }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('msg.operating')" fixed="right" width="300">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.status == 'YES'">
                <el-link type="primary" @click="addTemplate('look', scope.row)">查看详情</el-link>
              </el-button>
              <el-button type="text" v-else>
                <el-link type="primary" @click="addTemplate('edit', scope.row)">编辑信息</el-link>
              </el-button>
              <el-button type="text">
                <el-link type="primary" v-if="scope.row.status == 'YES'" @click="updateStatus(scope.row.id, 'NO')">暂停使用</el-link>
                <el-link type="primary" v-else @click="updateStatus(scope.row.id, 'YES')">恢复使用</el-link>
              </el-button>
              <el-button type="text" v-if="scope.$index" @click="moveTemp(scope.row.id, 'up')">
                <el-link type="primary">上移</el-link>
              </el-button>
              <el-button type="text" v-if="scope.$index != total - 1" @click="moveTemp(scope.row.id, 'down')">
                <el-link type="primary">下移</el-link>
              </el-button>
              <el-button type="text" @click="deleteTemp(scope.row.id)" v-if="scope.row.status != 'YES'">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
      </el-row>

      <!-- 标签管理 -->
      <el-dialog :visible.sync="visibleTag">
        <el-row slot="title">
          <el-col style="font-size: 18px; padding-left: 10px; line-height: 40px" :span="6">标签管理</el-col>
          <el-col :offset="12" :span="2"><el-button type="primary" @click="addTag()">新增标签</el-button></el-col>
        </el-row>
        <el-row class="content-box">
          <!-- 主体内容 -->
          <el-row>
            <!-- 表格 -->
            <el-table ref="mainTable" fit :data="tagData">
              <el-table-column label="序号" type="index" width="60"></el-table-column>
              <el-table-column prop="name" label="标签名称"></el-table-column>
              <el-table-column label="使用数量">
               <template slot-scope="scope">
                  {{scope.row.useHot ? scope.row.useHot : 0}}
               </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="addTag(scope.row)">
                    <el-link type="primary">编辑名称</el-link>
                  </el-button>
                  <el-button type="text" v-if="!scope.row.useHot">
                    <el-link type="danger" @click="deleteTag(scope.row.id)">删除</el-link>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination small layout="prev, pager, next" @current-change="changeIncomePage" :current-page.sync="incomePage" :page-size="incomeLimit" :total="incomeTotal"></el-pagination> -->
          </el-row>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visibleTag = false">取 消</el-button>
          <el-button type="primary" @click="visibleTag = false">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 新增标签 -->
      <el-dialog :title="tagTitle" :visible.sync="dialogAddTagVisible" width="600px">
        <el-form>
          <el-form-item label="标签名称">
            <el-input placeholder="请输入标签名称" v-model="tagForm.name" style="width: 400px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAddTagVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveTag()">确 定</el-button>
        </div>
      </el-dialog>
    </el-row>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { getTempPage, updateStatus, moveTemp, deleteTemp, getTagList, addTag, deleteTag } from '@/api/smtech/marketingTemplate.js'
  export default {
    data() {
      return {
        that: this,
        tableData: [], // 表格用户列表
        name: '', //模板名称
        status: '', //模板状态
        tableLoad: true, // 表格加载效果
        limit: 10, // 每页数
        page: 1, // 当前页
        total: 0, // 用户列表总条目数
        stateOpt: [
          { value: '全部', status: '' },
          { value: '正常', status: 'YES' },
          { value: '暂停', status: 'NO' },
        ], //模板状态列表

        /* 标签管理 */
        visibleTag: false, // 标签管理弹窗
        tagData: [], // 收入明细列表
        dialogAddTagVisible: false, //新增、修改标签弹窗
        tagForm: {}, //新增、修改标签数据
        tagTitle: '新增标签',
      }
    },
    computed: {
      ...mapState(['hotelInfo', 'dictData']),
    },
    mounted() {
      this.getMarketingTemplate()
    },
    methods: {
      // 获取营销模板列表
      getMarketingTemplate(page, type) {
        if (page) {
          this.page = page
        }
        if (type == 'reset') {
          this.name = ''
          this.status = ''
        }
        this.tableLoad = true
        let param = { name: this.name, status: this.status }
        getTempPage(this.limit, this.page, param).then((res) => {
          if (res.success) {
            this.tableLoad = false
            this.tableData = res.records
            this.total = res.total
          } else {
            this.tableLoad = false
            this.$message.error('获取数据失败!')
          }
        })
      },

      // 修改模板状态
      updateStatus(id, status) {
        let title, desc
        if (status == 'YES') {
          title = '暂停使用'
          desc = '是否确认暂停使用？酒店方将无法选择该模板'
        } else {
          title = '恢复使用'
          desc = '是否确认恢复使用？'
        }
        this.$confirm(desc, title, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.tableLoad = true
          updateStatus({ id, status }).then((res) => {
            if (res.success) {
              this.$message.success('修改成功!')
              this.tableLoad = false
              this.getMarketingTemplate()
            }
          })
        })
      },

      // 模板上下移
      moveTemp(id, step) {
        this.tableLoad = true
        moveTemp({ id, step }).then((res) => {
          if (res.success) {
            this.$message.success('修改成功!')
            this.getMarketingTemplate()
            this.tableLoad = false
          }
        })
      },

      // 删除模板
      deleteTemp(id) {
        this.$confirm('是否确认删除该模板？酒店方将无法选择该模板', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.tableLoad = true
          deleteTemp(id).then((res) => {
            if (res.success) {
              this.tableLoad = false
              this.$message.success('删除成功!')
              this.getMarketingTemplate()
            }
          })
        })
      },

      // 标签管理
      tagManagement() {
        this.visibleTag = true
        getTagList({ name: '' }, 10, 1).then((res) => {
          if (res.success) {
            this.tagData = res.records
          }
        })
      },

      // 新增标签
      addTag(data) {
        this.dialogAddTagVisible = true
        if (data) {
          this.tagTitle = '修改标签'
          this.tagForm.id = data.id
          this.$set(this.tagForm, 'name', data.name)
        } else {
          this.tagTitle = '新增标签'
        }
      },

      // 保存标签
      saveTag() {
        if (!this.tagForm.name) {
          this.$message.error('请输入标签名')
          return
        }
        this.tagForm.hotelId = this.hotelInfo.id
        addTag(this.tagForm).then((res) => {
          if (res.success) {
            this.$message.success(`${this.tagTitle}成功!`)
            this.dialogAddTagVisible = false
            this.tagManagement()
          }
        })
      },

      // 删除标签
      deleteTag(id) {
        this.$confirm('是否确认删除该标签', '删除标签', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          deleteTag(id).then((res) => {
            if (res.success) {
              this.$message.success('删除成功!')
              this.tagManagement()
            }
          })
        })
      },

      // 新增模板
      addTemplate(action, param) {
        sessionStorage.setItem('tempInfo', JSON.stringify(param))
        this.$router.push({ path: '/marketing_templat/add', query: { action } })
      },

      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.getMarketingTemplate()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.getMarketingTemplate()
      },
    },
    filters: {
      contentTypeFormat(type) {
        switch (type) {
          case 'TEXT':
            return '文字'
          case 'IMAGE':
            return '图片'
          case 'COMPOSITE':
            return '综合'
        }
      },
    },
    watch: {
      hotelInfo(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.getMarketingTemplate(1)
        }
      },
      dialogAddTagVisible(val) {
        if (!val) {
          this.tagForm = {}
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .marketing-members-cont {
    .content-box {
      .content-box {
         padding: 0;
        padding-bottom: 20px;
      }
    }
    .form-dialog-box {
      .el-form-item {
        width: 100%;
      }
    }
  }
</style>
